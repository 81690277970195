module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"0","icon":false,"className":"okk","maintainCase":false,"removeAccents":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650},
    },{
      plugin: require('../node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"trackingUrl":"cdn.usefathom.com","siteId":"LAARJJIX"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
