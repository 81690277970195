// eslint-disable-next-line no-unused-vars
const MTIProjectId = "b39498b8-43af-4f06-9f4c-48e7d5a81621"

const tracker = function() {
  const mtiTracking = document.createElement("script")
  mtiTracking.type = "text/javascript"
  mtiTracking.async = "true"
  mtiTracking.src = "mtiFontTrackingCode.js";
  (document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]).appendChild(mtiTracking)
}

tracker()
